import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import Wrapper from "../components/wrapper"
import SEO from "../components/seo"
import PhotoGallery from "../components/photo-gallery"

const galleryMeta = {
  "family/Brothers Communion.jpg": {
    caption: "Jason’s First Communion, 1980",
    alt: "",
  },
  "family/Brothers Marines.jpg": {
    caption: "Blair’s Graduation from Marine Corp Bootcamp, Marine Corp Recruit Depot, San Diego, Ca. 1997",
    alt: "",
  },
  "family/Cool Mom.jpg": {
    caption: "Cool Mom, late 1960s",
    alt: "",
  },
  "family/Dad Grape Press.jpg": {
    caption: "Our family has been making its own wine for generations. Here is dad pressing grapes.",
    alt: "",
  },
  "family/Dad Serving.jpg": {
    caption: "Here is Dad (left) waiting tables at The Montclair on Green Street in San Francisco’s North Beach District in the late 1960s. It is in this time where our parent’s met.",
    alt: "",
  },
  "family/Dad with Parents.jpg": {
    caption: "Dad with his parents, Ada and Amerigo.",
    alt: "",
  },
  "family/Family Dinner.jpg": {
    caption: "Family dinners were frequent. Italian dinners or Sunday roasts. This is our mother’s side. Grandparents, Great Grandmother, Aunt and Uncle, and us, of course.",
    alt: "",
  },
  "family/Giovanni and Dad.jpg": {
    caption: "Piero Restaurant on Pine Street in San Francisco. Dad is with our Great Grandfather, Giovanni, for whom Jason’s son is named.",
    alt: "",
  },
  "family/Grandpa Cooking.jpg": {
    caption: "Grandpa Luigi ready to work the grill. Pisa was where he grew up, and was his nickname.",
    alt: "",
  },
  "family/Grandpa SFIAC Soccer.jpg": {
    caption: "Our grandfather, Luigi (2nd from the right, standing), in his younger days playing soccer for the San Francisco Italian Athletic Club. This photo was taken in Washington Square after winning their league championship in the late 1940s.",
    alt: "",
  },
  "family/Jason Coat and Cap.jpg": {
    caption: "Jason at about age three ready for San Francisco weather.",
    alt: "",
  },
  "family/Jason Wedding Bride and Groom.jpg": {
    caption: "Jason with his new bride, Amy. 2006",
    alt: "",
  },
  "family/Jason Wedding Brothers.jpg": {
    caption: "The Brothers. Best Man and Groom.",
    alt: "",
  },
  "family/Brothers Olan Mills.jpg": {
    caption: "Olan Mills Studio was THE place for family portraits, back in the day.",
    alt: "",
  },
}

const StoryPage = () => {
  const galleryQuery = useStaticQuery(graphql`
    query {
      gallery: allFile(filter: { relativeDirectory: { eq: "family" } }) {
        edges {
          node {
            id
            relativePath
            photos: childImageSharp {
              id
              gatsbyImageData(
                formats: [AUTO, WEBP, AVIF]
                placeholder: BLURRED
                width: 2400
                quality: 70
                avifOptions: { quality: 55 }
              )
            }
            thumbnails: childImageSharp {
              id
              gatsbyImageData(
                formats: [AUTO, WEBP, AVIF]
                placeholder: BLURRED
                width: 1640
                quality: 70
                avifOptions: { quality: 55 }
                aspectRatio: 1
                transformOptions: { cropFocus: ATTENTION }
              )
              resize(height: 154) {
                src
              }
            }
          }
        }
      }
    }
  `)

  const galleryData = galleryQuery.gallery

  return (
    <Layout>
      <SEO title="Our Story" />
      <Wrapper sizeReadable>
        <h2>Our Story</h2>
        <p>
          Our story is unique to us, but not uncommon to other kids born into
          families whose parents or grandparents immigrated from other
          countries. We grew up with our feet firmly planted in two worlds; the
          Old and the New. Italian was the dominant language growing up, as many
          of our elders did not speak English. Sunday roasts and hamburgers were
          as much a part of our diets as chicken cacciatore and the deep, rich
          red pasta sauces, cooked on the stovetop for hours, that our mother,
          or grandmothers, cooked. Italian weddings were, and still are, big,
          boisterous affairs, as were the many picnics we attended hosted by
          Italian social clubs in The Bay Area.
        </p>

        <p>
          In this environment, we learned the importance of family, loyalty, and
          hard work. Our fathers, grandfathers and uncles were fishermen,
          masons, engineers, and veterans. Our mothers, grandmothers, and aunts
          raised families, worked in offices, and owned their own businesses. We
          learned you don’t give up when things get hard, you look to those who
          love you for support, and you support them right back when they need
          it.
        </p>

        <p>
          It is on their backs, through their hard work and sacrifice, that we
          are the men we are today.
        </p>

        <p>
          Restaurants specifically, and hospitality in general, is, in its very
          essence, an extension of all of those attributes and qualities we
          observed in our childhood. When you come into Brothers Public Kitchen,
          you are family, we want to take care of you, we may try to offer you
          more food than you could possibly eat in one sitting, and, most
          importantly, we want to see you again.
        </p>

        <p>
          Brothers Public Kitchen is an homage to our family as much as it is
          attempt at our own American dream; a dream which began on distant
          shores, wrapped in the hope and optimism of a better future.{" "}
        </p>
      </Wrapper>

      <Wrapper sizeReadable>
        <PhotoGallery
          galleryData={galleryData.edges}
          galleryMeta={galleryMeta}
          group="family"
          thumbSrcSizes="(min-width: 818px) 380px, (min-width: 992px) 444px, 45vw"
        />
      </Wrapper>
    </Layout>
  )
}

export default StoryPage
